import FluroContent from ".";

class Competition extends FluroContent {
    constructor() {
        super("competition");
    }
}

const _Competition = new Competition();
export default _Competition;
