<template>
    <div class="app-tile-image-container">
        <template v-if="src">
            <v-img class="app-tile-image rounded-lg d-flex align-center justify-center grey" :src="src" :contain="contain">
                <template v-slot:placeholder>
                    <div class="app-tile-image-loader grey" align="center" justify="center">
                        <v-progress-circular :width="2" :size="20" color="dark" indeterminate></v-progress-circular>
                    </div>
                </template>
            </v-img>
        </template>

        <template v-else>
            <div class="app-tile-image rounded-lg d-flex align-center justify-center grey" align="center" justify="center">
                <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "app-image",

    props: {
        src: {
            type: String,
        },
        contain: {
            type: Boolean,
        },
    },
};
</script>

<style lang="scss">
.app-tile-image-container {
    position: relative;
    padding-top: 56.65%;
    width: 100%;
    margin-bottom: 12px;
    flex-shrink: 0;

    .app-tile-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .app-tile-image-loader {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
